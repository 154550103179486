import { useLanguage } from "../../hooks/useLanguage";

import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Skeleton,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue
} from '@chakra-ui/react';
// import { BsPerson } from 'react-icons/bs';
import { FaPrayingHands } from 'react-icons/fa';
import { useFetch } from "../../hooks/useFetch";

function StatsCard(props) {
    const { title, stat, icon, isLoading } = props;
    return (
        <Box
            px={2}
            py={2}
            shadow="md"
            border={'1px solid'}
            borderColor={"blue.base"}
            rounded={'lg'}
        >
            <Flex justifyContent={'space-between'}>
                <Box pl={2}>
                    <Stat>
                        <StatLabel fontWeight={'medium'} isTruncated>
                            {title}
                        </StatLabel>
                        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                            {isLoading === true ? <Skeleton height={"28px"} width={"80px"} /> : stat}
                        </StatNumber>
                    </Stat>
                </Box>
                <Box
                    pl={2}
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}>
                    {icon}
                </Box>
            </Flex>
        </Box>
    );
}

export function Stats() {
    const { text, locale } = useLanguage()
    const { data, error } = useFetch('/api/getdata')
    if (error) return <Box my={8}>Failed to load stats</Box>
    const peopleFormatted = new Intl.NumberFormat('pt-BR').format(data?.people)
    const hailMariesFormatted = new Intl.NumberFormat('pt-BR').format(data?.hail_maries)

    return (
        <Box
            mx={'auto'}
            px={2}
            py={8}
            color="blue.base"
            border="1px solid"
            borderColor="blue.base"
        >
            <chakra.h1
                textAlign={'center'}
                fontSize={'xl'}
                pb={6}
                lineHeight={"1"}
                fontFamily={"Roboto Slab"}
            >
                Nosso objetivo está cada vez mais próximo
            </chakra.h1>
            <SimpleGrid columns={{ base: 1, md: 1 }} maxW="80%" mx="auto" spacing={2}>
                {/* <StatsCard
                    title="Pessoas"
                    stat={peopleFormatted}
                    icon={<BsPerson size={'3em'} color="black" />}
                    isLoading={!data === true}
                /> */}
                <StatsCard
                    title="Ave-Marias"
                    stat={hailMariesFormatted}
                    icon={<FaPrayingHands size={'3em'} color="blue.base" />}
                    isLoading={!data === true}
                />
            </SimpleGrid>
        </Box>
    );
}