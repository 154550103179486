import Head from "next/head";
import { useRouter } from "next/router";

// Execute a CLI da Vercel para fazer deploy em ambiente de desenvolvimento
// Depois acesse o site abaixo para ver como que ficaram as metaTags
// https://metatags.io/

const organization = {
    pageTitle: 'IPCO',
    facebookAuthor: 'https://www.facebook.com/Inst.PCO',
    facebookPublisher: 'https://www.facebook.com/Inst.PCO',
}

const BASE_URL = process.env.NEXT_PUBLIC_PROJECT_URL || 'https://rezecontraocomunismo.org.br'

export function Meta(props) {
    const {
        title,
        description,
        socialTitle,
        pageType,
        image,
        createdAt
    } = props

    const router = useRouter()

    return <Head>
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
            rel="mask-icon"
            href="/favicon/safari-pinned-tab.svg"
            color="#000000"
        />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
        <meta name="theme-color" content="#000" />
        {/*<link rel="alternate" type="application/rss+xml" href="/feed.xml"/>*/}

        {/*Primary Meta Tags*/}
        <title>{title} | {organization.pageTitle}</title>
        <meta name="title" content={socialTitle} />
        <meta name="description" content={description} />

        {/*Open Graph / Facebook*/}
        <meta property="og:type" content={pageType} />
        <meta property="og:url" content={BASE_URL + router.asPath} />
        <meta property="og:title" content={socialTitle} key="title" />
        <meta property="og:description" content={description} />
        <meta property='article:author' content={organization.facebookAuthor} />
        <meta property='article:publisher' content={organization.facebookPublisher} />
        <meta property="article:published_time" content={createdAt} />
        <meta property='og:site_name' content={BASE_URL} />
        <meta property="og:image" content={image} />

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={BASE_URL + router.asPath} />
        <meta property="twitter:title" content={socialTitle} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
    </Head>
}